<template>
  <layout-login>
    <template v-slot:title>
      {{ __('interface.register-request-code') }}
    </template>
    <ion-item>
      <ion-label position="floating">{{ __('interface.email') }}</ion-label>
      <ion-input type="email" v-model="form.email" required/>
    </ion-item>
    <ion-button expand="full" class="ion-margin-vertical" @click="actionRegister">
      {{ __('interface.request-code') }}
    </ion-button>
    <div class="ion-text-center ion-padding">
      <router-link to="/login">{{ __('interface.go-login') }}</router-link>
    </div>
  </layout-login>
</template>

<script>
import {Auth} from '@aws-amplify/auth'
import LayoutLogin from '@/layouts/LayoutLogin.vue';
import {alertController} from "@ionic/vue";

export default {
  name: 'RegisterRequestCode',
  components: {
    LayoutLogin
  },
  data() {
    return {
      form: {
        email: '',
        code: ''
      },
      loadingState: false
    }
  },
  methods: {
    async showAlert(error) {
      const alert = await alertController.create({
        header: 'Error',
        message: error,
        buttons: ['OK'],
      });
      return alert.present();
    },
    async actionRegister() {
      this.loadingState = true

      Auth.resendSignUp(this.form.email).then(() => {
        this.$router.push({name: 'RegisterConfirmCode', query: {email: this.form.email}})
        this.$tracking.event({
            eventName: 'code-requested',
            category: 'registration',
            event: {
              username: this.form.email
            }
          });
      }).catch(error => {
        this.showAlert(error.message)
      }).finally(() => {
        this.loadingState = false
      })
    }
  }
}
</script>
